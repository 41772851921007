import { adsAxiosInstance } from 'api/adsAxiosClient'
import { cmsAxiosInstanceWithoutHeaders } from 'api/axiosClient'
import { CMS_ACCESS_TOKEN } from 'constants/process'
import { LandingPage } from 'containers/PublicPages/LandingPage'
import { withAuth } from 'hocs/withAuth'
import { withAuthServerSideProps } from 'hocs/withAuth/withAuthServerSideProps'

const REVALIDATE_IN_SEC = 604800 //1 week

const getFaqs = async () => {
  try {
    const response = (
      await cmsAxiosInstanceWithoutHeaders.get('/faq', {
        headers: {
          Authorization: `Bearer ${CMS_ACCESS_TOKEN}`,
        },
      })
    ).data
    return response?.data?.attributes?.faq
  } catch (e) {
    console.error('Error fetching faqs: ', e)
    return []
  }
}

const getTestimonials = async () => {
  try {
    return (
      await cmsAxiosInstanceWithoutHeaders.get('reviews?populate=image', {
        headers: {
          Authorization: `Bearer ${CMS_ACCESS_TOKEN}`,
        },
      })
    ).data?.data?.map?.(({ attributes }) => attributes)
  } catch (err) {
    console.error('error on fetching Testimonials', err)
    return []
  }
}

const getLocationMarkers = () => {
  return fetch(
    'https://fin-vault.nyc3.digitaloceanspaces.com/dev/front-end/globe-json/globe-data.json'
  )
    .then((response) => response.json())
    .catch((error) => {
      console.error(error)
      return []
    })
}

const getRecommendedCompanies = async (): Promise<any[]> => {
  try {
    const response = (await adsAxiosInstance.get('/recommended-companies/50'))
      .data
    return response
  } catch (e) {
    console.error('Error getting recommended companies: ', e)
    return []
  }
}

const fetchInfo = async () => {
  const [faqs, testimonials, locationMarkers, companies] = await Promise.all([
    getFaqs(),
    getTestimonials(),
    getLocationMarkers(),
    getRecommendedCompanies(),
  ])

  return {
    faqs,
    testimonials,
    locationMarkers,
    companies,
  }
}

export const getStaticProps = withAuthServerSideProps(
  fetchInfo,
  REVALIDATE_IN_SEC
)

export default withAuth(LandingPage, {
  LoggedOutComponent: LandingPage,
  naiveCheck: true,
  forceShowBannerOnLogout: true,
})
